const MEDICAL_VALUABLE_IMAGE = new Map()

MEDICAL_VALUABLE_IMAGE.set("CPAP",'https://storage.googleapis.com/pettag/qr/assets/cpap.png')
MEDICAL_VALUABLE_IMAGE.set("Apnea Sleep Holder Mask",'https://storage.googleapis.com/pettag/qr/assets/sleepholdermask.png')
MEDICAL_VALUABLE_IMAGE.set("Nebulizer",'https://storage.googleapis.com/pettag/qr/assets/nebulizer.png')
MEDICAL_VALUABLE_IMAGE.set("Diabetes Supplies",'https://storage.googleapis.com/pettag/qr/assets/diabetessupplies.png')
MEDICAL_VALUABLE_IMAGE.set("Portable Concentrator",'https://storage.googleapis.com/pettag/qr/assets/portableconcentrator.png')
MEDICAL_VALUABLE_IMAGE.set("Oxygen Unit",'https://storage.googleapis.com/pettag/qr/assets/oxygenunit.png')
MEDICAL_VALUABLE_IMAGE.set("Respiratory Machines/Accessories",'https://storage.googleapis.com/pettag/qr/assets/respiratorymachine.png')
MEDICAL_VALUABLE_IMAGE.set("Medical Equipment",'https://storage.googleapis.com/pettag/qr/assets/medical-equipment.png')

export default MEDICAL_VALUABLE_IMAGE