import React from "react";
import { TextField, Tooltip } from "@material-ui/core";

function Inputfield({
  label,
  type,
  fieldname,
  value,
  onchange,
  inputprops,
  labelprops,
  required,
  helper,
  disabled
}) {
  return (
    <>
      <div className="bagactivation-label-container">
        {
          fieldname === "display_name" ? <>
           <Tooltip
        describeChild
        title="This name will appear on the scan page when the tag is scanned."
        leaveTouchDelay={3000}
        enterTouchDelay={50}
      >
      <TextField
          variant="outlined"
          type={type}
          // className="bagactivation-input-tag"
          label={label}
          name={fieldname}
          value={value}
          onChange={onchange}
          InputLabelProps={labelprops}
          inputprops={inputprops}
          required={required ? true : false}
        />
        </Tooltip>
          </> :    <TextField
          
          variant="outlined"
          type={type}
          // className="bagactivation-input-tag"
          label={label}
          name={fieldname}
          value={value}
          onChange={onchange}
          InputLabelProps={labelprops}
          InputProps={inputprops}
          required={required ? true : false}
          helperText = {helper}
      
        />
        }
     
      </div>
    </>
  );
}

export default Inputfield;
