import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./vehicledetails.component.css";
import "font-awesome/css/font-awesome.min.css";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import $ from "jquery";
import Navbar from "../header/header.component.jsx";
import axios from "axios";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import imageCompression from "browser-image-compression";
import { Link } from "@mui/material";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
// import CircularProgress from "@mui/material/CircularProgress";
import { APP_ENV, switchUrls } from "../../api/index.js";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import setCanvasPreview from "../../setcanvasPreview.js";
import heic2any from "heic2any";
import Footer from "../mainfooter/footerblack.jsx";
import MEDICAL_VALUABLE_IMAGE from "../../utils/Helpers.js";
import Inputfield from "../ui/Inputfield.jsx";
import Sidebarcontent from "../sidebar/Sidebar.jsx";
import { InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const baseURL = switchUrls("vehicle");
const gateway = switchUrls("gateway");
const gatewayFrontend = switchUrls("gateway_frontend");
const bagFrontend = switchUrls("vehicle_frontend");

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
let usertoken = readCookie("token");

export default class BagDetails extends React.Component {
  fileObj = [];
  fileArray = [];
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
    this.imageref = React.createRef();
    this.canvasref = React.createRef();
    this.state = {
      imagesArray: "",
      imageUrlvector:
        "https://storage.googleapis.com/pettag/qr-vehicle/vectors/car%20(1).png",
      imageUrl: "",
      vectorurl: "",
      vehicle_imagelocation: null,
      bag_imagename: null,
      selectedImage: null,
      tag_number: this.props.match.params.tag_number,
      tag_status: "active",
      valuable_type: "Luggage",
      valuable_id: null,
      valuableTypeToIdMap: {
        "Laptop Bag": 1,
        Briefcase: 2,
        "Camera Bag": 3,
        "Gym Bag": 4,
        "Trolley Bag": 5,
        Suitcase: 6,
        "Ladies Purse": 7,
        "Sports Kit Bag": 8,
        "Duffel Bag": 9,
        "Other Bags": 10,
        "School Bag": 11,
        Luggage: 12,
      },
      valuableImages: {
        1: "laptopbag.png",
        2: "briefcase.png",
        3: "camerabag.png",
        4: "GymBag.png",
        5: "trolley-bag.png",
        6: "suitcase.png",
        7: "ladiespurse.png",
        8: "sport-bag.png",
        9: "duffelbag.png",
        10: "other.png",
        11: "schoolbag.png",
        12: "luggage.png",
      },
      // imageUrl: "",
      description: "",
      date_of_purchase: "",
      file: [null],
      bag_brand: "",
      bag_color: "",
      warranty_period: "",
      snackbaropen: false,
      isuploading: false,
      snackbarmsg: "",
      sidebar: true,
      subMenu: true,
      message: "",
      showLoader: true,
      imagemodal: false,
      crop: "",
      uploaderror: false,
      imagecroploading: false,
      errorstate: null,
      croperror: false,
      digital_product: false,
      journey_startdate: "",
      journey_enddate: "",
      medical_tag: false,
      digitaltag_information: {
        tag_number: "",
        digital_product: false,
        airLine: "",
        bag_brand1: "",

        bag_description1: "",

        pnr: "",

        orgin: "",
        destination: "",
        journey_startdate: "",
        journey_enddate: "",
        bag_imagelocation: "",
        passenge1_firstname: "",
        passenger1_lastname: "",
        bag_image1: "",
        bag_image2: "",
        display_name: "",
      },
      current_lock: "",
      display_baglock: false,
      warranty_value: 0,
      warranty_time: ["Days", "Months", "Years"],
      warranty_time_period: "Days",
      number_plate: "",
      model_name: "",
      model_color: "",
      Car_brand : ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.showSidebar = this.showSidebar.bind(this);
    this.onImgChange = this.onImgChange.bind(this);
    this.showSubMenu = this.showSubMenu.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.cropsetup = this.cropsetup.bind(this);
    this.handelimagecrop = this.handelimagecrop.bind(this);
    this.handelcancel = this.handelcancel.bind(this);
    this.uploadImg = this.uploadImg.bind(this);
    this.handelwarranty = this.handelwarranty.bind(this);
    this.handelredirection = this.handelredirection.bind(this);
  }
  handelredirection() {
    window.location.href = `${bagFrontend}/#/bag-lock/${this.props.match.params.tag_number}?changelock=true`;
  }
  handelwarranty(e) {
    this.setState({
      [e?.target?.name]: e?.target?.value,
    });
  }
  handelcancel() {
    this.setState({
      uploaderror: false,
      message: "",
      imagemodal: false,
      crop: "",
      selectedImage: "",
      imageUrl: "",
    });
  }

  uploadImg() {
    this.setState({
      uploaderror: false,
      message: false,
      imagemodal: false,
      imagecroploading: false,
      croperror: false,
    });
  }

  componentDidMount() {
    axios
      .get(
        `${baseURL}/api/vehicle/getvehicleDetails/` +
          this.props.match.params.tag_number
      )
      .then(({ data }) => {
        // console.log(response, "ress");

        this.setState({
          number_plate: data?.number_plate,
          display_name: data?.display_name || localStorage?.getItem("name"),
          model_name: data?.model_name,
          model_color: data?.model_color,
          vehicle_imagelocation: data?.vehicle_imagelocation,
          Car_brand : data?.Car_brand
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          snackbaropen: true,
          snackbarmsg: error?.response?.data?.error || "failed",
          showLoader: false,
        });
      });
    window.addEventListener("load", this.handlePageLoad);
    setTimeout(this.handlePageLoad, 3000);
    $("#profile-image-uploadActi").on("click", function () {
      $("#choose-profile-imageActi").trigger("click");
    });

    $("#profile-image-uploadActi-1").on("click", function () {
      $("#choose-profile-imageActi").trigger("click");
    });

    setTimeout(() => {
      this.setState({ showLoader: false });
    }, 1000);
  }
  componentWillUnmount() {
    window.removeEventListener("load", this.handlePageLoad);
  }
  onImgChange(event) {
    this.setState({ imagesArray: "", file: [null] });
    this.fileObj = [];
    this.fileArray = [];
    this.setState({
      imagesArray: [...this.state.imagesArray, ...event.target.files],
    });
    this.fileObj.push(event.target.files);

    for (let i = 0; i < this.fileObj[0].length; i++) {
      this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]));
    }

    this.setState({ file: this.fileArray });
  }
  handleDropdownChange(event) {
    const selectedValuableType = event.target.value;
    const selectedId = this.state.valuableTypeToIdMap[selectedValuableType];
    if (!this.state?.medical_tag) {
      this.setState({
        valuable_type: selectedValuableType,
        valuable_id: selectedId,
        imageUrlvector: `https://storage.googleapis.com/pettag/qr/assets/${this.state.valuableImages[selectedId]}`,
        isuploading: false,
      });
    } else {
      this.setState({
        valuable_type: selectedValuableType,
        valuable_id: selectedId,
        imageUrlvector: MEDICAL_VALUABLE_IMAGE?.get(selectedValuableType),
        isuploading: false,
      });
    }
  }
  // handlePageLoad = () => {
  //   this.setState({ isLoading: false });
  // };
  imageSize(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const promise = new Promise((resolve, reject) => {
      reader.onload = function (e) {
        const image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          const { height, width } = this;
          resolve({ height, width });
        };
        image.onerror = reject;
      };
    });

    return promise;
  }
  async handleChange(event) {
    console.log("..............................");
    console.log(".l");
    console.log(event.target.files[0].size);
    this.setState({ imagecroploading: true });
    if (event.target.files[0]) {
      var sizeinmb = event.target.files[0].size / (1024 * 1024);
      console.log(sizeinmb);

      this.setState({ showLoader: true });
      const imageDimension = await this.imageSize(event.target.files[0]);
      //console.log(imageDimension);
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight:
          imageDimension?.width > 1300 ? 1300 : imageDimension?.width,
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(
        event.target.files[0],
        options
      );
      //console.log(compressedFile);

      const selectedImage = compressedFile;
      console.log(selectedImage);
      this.setState({ showLoader: false });

      if (selectedImage?.type) {
        if (
          selectedImage?.type === "image/jpeg" ||
          selectedImage?.type === "image/png" ||
          selectedImage?.type === "image/gif"
        ) {
          this.setState({
            imagemodal: true,
            selectedImage: compressedFile,
            imageUrl: URL.createObjectURL(compressedFile),
            isuploading: true,
            imagecroploading: false,
          });
        } else if (
          selectedImage?.type === "image/heic" ||
          selectedImage?.type === "image/heif"
        ) {
          const convertedImageBlob = await heic2any({
            blob: compressedFile,
            toType: "image/jpeg",
            quality: 0.1, // cuts the quality and size
          });
          console.log(convertedImageBlob);
          let sizeinmb = convertedImageBlob?.size / (1024 * 1024);
          console.log(sizeinmb);
          if (sizeinmb > 5) {
          }
          this.setState({
            imagemodal: true,
            selectedImage: compressedFile,
            imageUrl: URL.createObjectURL(compressedFile),
            isuploading: true,
            imagecroploading: false,
          });
        } else {
          this.setState({
            message: "Only Png and Jpeg Images Are Acceptable",
            uploaderror: true,
            imagemodal: true,
            imagecroploading: false,
          });
        }
      } else {
        this.setState({
          message: "Only Png and Jpeg Images Are Acceptable",
          uploaderror: true,
          imagemodal: true,
          imagecroploading: false,
        });
      }

      if (this.fileInputRef.current) {
        this.fileInputRef.current.value = "";
      }
    }
  }
  showSidebar(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      sidebar: !currentState.sidebar,
    }));
  }
  showSubMenu(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      subMenu: !currentState.subMenu,
    }));
  }
  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  deleteimage = (e) => {
    // console.log(e, "eeee");
    // console.log(this.state.imageUrl, "imageurk");
    // console.log(this.state, "state");
    // this.setState({
    //   snackbaropen: true,
    //   snackbarmsg: "Feature coming soon....!",
    // });
    if (this.state.imageUrl) {
      const formattedValuableType = this.state.valuable_type.replace(
        /([a-z])([A-Z])/g,
        "$1 $2"
      );
      const selectedId = this.state.valuableTypeToIdMap[formattedValuableType];
      const selectedImageFilename = this.state.valuableImages[selectedId];
      // const imageUrlvectors = `https://storage.googleapis.com/pettag/qr/assets/${selectedImageFilename}`;
      this.setState({
        imageUrlvector: `https://storage.googleapis.com/pettag/qr/assets/${selectedImageFilename}`,
        imageUrl: null,
        selectedImage: null,
      });
    }
    axios
      .patch(`${baseURL}/api/vehicle/deletevehicleimage`, this.state)
      .then((response) => {
        console.log(response);
        if (response.data === "Bag Image removed") {
          this.setState({
            imageUrl: "",
            vehicle_imagelocation: null,
            bag_imagename: null,
          });
        } else if (response.data === "wrong data") {
          this.setState({
            snackbaropen: true,
            snackbarmsg: "Image could not be deleted",
          });
        }
      })
      .catch((error) => {
        this.setState({ snackbaropen: true, snackbarmsg: "Unsuccessful" });
      });
  };

  // submitHandler = (e) => {
  //   e.preventDefault();
  //   console.log(this.state);
  //   const {
  //     tag_status,
  //     valuable_type,
  //     imageUrl,
  //     description,
  //     date_of_purchase,
  //     bag_color,
  //     bag_brand,
  //     valuable_id,
  //     bag_imagelocation,
  //   } = this.state;
  //   console.log(imageUrl, "imageurl");
  //   console.log(bag_imagelocation, "location");
  //   const data = {
  //     tag_number: this.props.match.params.tag_number,
  //     tag_status,
  //     valuable_type,
  //     imageUrl,
  //     description,
  //     date_of_purchase,
  //     bag_color,
  //     bag_brand,
  //     valuable_id,
  //   };
  //   axios
  //     .post(
  //       `${baseURL}/api/bag/editBagDetails/` +
  //         this.props.match.params.tag_number,
  //       data,
  //       { headers: { Authorization: usertoken } }
  //     )
  //     .then((response) => {
  //       console.log(response);
  //       if (response.data === "Bag Details Updated Successfully") {
  //         window.location.href = "/dashboard/" + localStorage.getItem("uid");
  //         const fd = new FormData();
  //         console.log(this.state.selectedImage, "selected Image");
  //         console.log(this.state.imageUrlvector, "valuable");
  //         // if (this.state.imageUrlvector) {
  //         //   const selectedImageFilename = this.state.imageUrlvector;
  //         //   const imageUrl = `https://storage.googleapis.com/pettag/qr/assets/${selectedImageFilename}`;
  //         //   console.log(imageUrl, "url");
  //         //   fd.append("image", "");
  //         // } else {
  //         // }
  //         fd.append("image", this.state.selectedImage);
  //         fd.append("tag_number", this.props.match.params.tag_number);
  //         axios
  //           .post(`${baseURL}/api/bag/bagimageupload`, fd)
  //           .then((res) => {
  //             console.log(res);
  //             this.setState({
  //               snackbaropen: true,
  //               snackbarmsg: "Bag Details Updated Successfully",
  //             });
  //           })
  //           .catch((error) => {
  //             console.log(error);
  //           });
  //       } else if (response.data === "Bag Details Update Failed") {
  //         this.setState({ snackbaropen: true, snackbarmsg: response.data });
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       this.setState({
  //         snackbaropen: true,
  //         snackbarmsg: "Bag Details Update Failed",
  //       });
  //     });
  // };
  submitHandler = (e) => {
    e.preventDefault();
    console.log(this.state, "");
    const {
      tag_number,
      vehicle_imagelocation,
      vectorurl,
      imageUrlvector,
      display_name,
      model_color,
      model_name,
      number_plate,
      Car_brand
    } = this.state;

    const updatedBagImagelocation = vehicle_imagelocation
      ? vehicle_imagelocation
      : null;

    const data = {
      tag_number: this.props.match.params.tag_number,

      display_name,
      model_color,
      model_name,
      number_plate,
      Car_brand
    };

    axios
      .put(
        `${baseURL}/api/vehicle/editvehicleDetails/` +
          this.props.match.params.tag_number,
        data,
        { headers: { Authorization: usertoken } }
      )
      .then((response) => {
        const fd = new FormData();

        if (this.state?.selectedImage !== null) {
          fd.append("image", this.state.selectedImage);
          fd.append("tag_number", this.props.match.params.tag_number);
          this.setState({
            showLoader: true,
          });
          axios
            .patch(`${baseURL}/api/vehicle/vehicleimageupload`, fd)
            .then((res) => {
              console.log(res);
              this.setState({
                showLoader: false,
              });
              this.setState({
                snackbaropen: true,
                snackbarmsg: "Vehicle Details Updated Successfully",
              });
              if (APP_ENV === "local") {
                window.location.href = gatewayFrontend;
              } else {
                window.location.href =
                  "/dashboard/" + localStorage.getItem("uid");
              }
            })
            .catch((error) => {
              console.log(error);
              this.setState({
                showLoader: false,
                errorstate: `API Error Is${error}`,
              });
              this.setState({
                snackbaropen: true,
                snackbarmsg: "image upload failed",
              });
              /* window.location.href =
                  "/dashboard/" + localStorage.getItem("uid");*/
            });
        } else {
          this.setState({
            snackbaropen: true,
            snackbarmsg: "Vehicle Details Updated Successfully",
          });
          if (APP_ENV === "local") {
            window.location.href = gatewayFrontend;
          } else {
            window.location.href = "/dashboard/" + localStorage.getItem("uid");
          }
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          snackbaropen: true,
          snackbarmsg: "Vehicle Details Update Failed",
        });
      });
  };
  cropsetup(e) {
    console.log("dddddddddddd");
    console.log(e.currentTarget);
    const { width, height } = e.currentTarget;
    console.log(width, height);
    const cropminwidth = (150 / width) * 100;
    const cropAr = makeAspectCrop(
      {
        unit: "%",
        width: cropminwidth,
      },
      1,
      width,
      height
    );
    console.log(cropAr);
    const cropcenter = centerCrop(cropAr, width, height);

    this.setState({ crop: cropcenter });
  }
  async handelimagecrop() {
    try {
      this.setState({ imagecroploading: true });
      console.log(this.imageref?.current.complete);

      setCanvasPreview(
        this.imageref?.current,
        this.canvasref?.current,
        convertToPixelCrop(
          this.state.crop,
          this.imageref?.current?.width,
          this.imageref?.current?.height
        )
      );

      this.canvasref?.current.toBlob(
        async (blob) => {
          try {
            if (blob) {
              let fileType = blob?.type; // Get the MIME type of the Blob
              let file = new File([blob], this.state?.selectedImage?.name, {
                type: fileType || "image/jpeg",
              });

              if (file?.size) {
                let sizeinmb = file?.size / (1024 * 1024);
                if (sizeinmb > 5) {
                  this.setState({
                    message: "Upload A low Quality Image",
                    uploaderror: true,
                    imagemodal: true,
                    imagecroploading: false,
                  });
                } else {
                  this.setState({
                    selectedImage: file,
                    imageUrl: URL.createObjectURL(blob),
                    key_imagelocation: null,
                    isuploading: true,
                    imagemodal: false,
                    imagecroploading: false,
                  });
                }
              }
            } else {
              this.setState({
                uploaderror: true,
                imagemodal: true,
                imagecroploading: false,
                message:
                  "Please upload images with dimensions no larger than 3000px x 3000px for Performing Croping",
                croperror: true,
              });
            }
          } catch (er) {
            console.log(er);

            this.setState({
              errorstate: `croping error :${er}`,
              isuploading: true,
              imagecroploading: false,
              uploaderror: true,
            });
          }
        },
        "image/jpeg",
        0.1
      );
    } catch (er) {
      console.log(er);

      this.setState({
        errorstate: `croping error :${er}`,
        isuploading: true,
        imagecroploading: false,
        uploaderror: true,
      });
    }
  }

  render() {
    const {
      vehicle_imagelocation,
      imageUrl,
      imageUrlvector,
      bag_imagename,
      valuable_type,
      tag_number,
      date_of_purchase,
      warranty_period,
      description,
      bag_brand,
      bag_color,
      // vectorurl,
      sidebar,
      subMenu,
      valuableTypeToIdMap,
      valuableImages,
      valuable_id,
      isuploading,
      uploaderror,
      message,
      imagemodal,
      crop,
      digital_product,
      journey_startdate,
      journey_enddate,
      medical_tag,
      display_name,
      current_lock,
      display_baglock,
      warranty_month,
      warranty_limit,
      warranty_year,
      warranty_value,
      warranty_time,
      warranty_time_period,
      number_plate,
      model_name,
      model_color,
      Car_brand
    } = this.state;

    document.onreadystatechange = () => {
      this.setState({ showLoader: true });
      if (document.readyState === "complete") {
        this.setState({ showLoader: false });
      }
    };

    return (
      <>
        <Navbar />
        <div className="editPetDetails-main-wrapper">
          <Sidebarcontent />

          <>
            <div class="bag-container">
              <div className="background-image">
                <div className="bag-activation-container">
                  <p>
                    <strong>Vehicle Details</strong>
                  </p>

                  <div className="bag-activation-form-container">
                    <form
                      onSubmit={this.submitHandler}
                      className="bag-activation-form"
                    >
                      <div className="editPetDetails-user-img">
                        {imageUrl?.length ? (
                          <>
                            <img
                              alt="imageurl"
                              src={imageUrl}
                              className="editPetDetails-image"
                            />
                          </>
                        ) : vehicle_imagelocation ? (
                          <>
                            <img
                              alt="imagelocation"
                              src={vehicle_imagelocation}
                              className="editPetDetails-image"
                            />
                          </>
                        ) : (
                          <>
                            <img
                              alt="imageyrlvector"
                              src={imageUrlvector}
                              className="editPetDetails-image"
                            />
                          </>
                        )}
                        <div className="editPetDetails-edit-btn-wrap">
                          <label
                            htmlFor="upload"
                            className="editPetDetails-edit-button-image"
                            style={{ cursor: "pointer" }}
                          >
                            <MdIcons.MdEdit /> Change
                          </label>

                          <input
                            id="upload"
                            accept="image/*"
                            className="editPetDetails-input-user-img"
                            type="file"
                            ref={this.fileInputRef}
                            onChange={this.handleChange}
                          />
                          {imageUrl || vehicle_imagelocation ? (
                            <label
                              className="editPetDetails-delete-button-image "
                              onClick={this.deleteimage}
                            >
                              <AiIcons.AiFillDelete /> Remove
                            </label>
                          ) : null}
                        </div>
                        <div
                          className=" editPetDetails-user-qr mt-3 d-flex align-items-center justify-content-center"
                          style={{ gap: "6px" }}
                        >
                          <img
                            alt="qr"
                            src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                            style={{ width: "30px", height: "30px" }}
                          />
                          <p style={{ marginTop: "15px" }}>
                            <b>
                              {tag_number && tag_number !== "-"
                                ? tag_number
                                : "N/A"}
                            </b>
                          </p>
                        </div>
                      </div>
                      <div>
                      <Inputfield
                          label={"Display name"}
                          fieldname={"display_name"}
                          value={display_name}
                          onchange={this.changeHandler}
                          required={true}
                          labelprops={{ shrink: true }}
                        />
                        <Inputfield
                          label={"Number Plate"}
                          fieldname="number_plate"
                          type="text"
                          value={number_plate}
                          onchange={this.changeHandler}
                        />

                     
                        <Inputfield
                          label={"Model Name"}
                          type="text"
                          fieldname={"model_name"}
                          value={model_name}
                          onchange={this.changeHandler}
                        />
                         <Inputfield
                          label={"Car Brand"}
                          type="text"
                          fieldname={"Car_brand"}
                          value={Car_brand}
                          onchange={this.changeHandler}
                        />
                        <Inputfield
                          label={"Color"}
                          type="text"
                          fieldname={"model_color"}
                          value={model_color}
                          onchange={this.changeHandler}
                        />

                        <div className="bagactivation-btn-grp">
                          <Button
                            variant="contained"
                            type="submit"
                            style={{ width: "100%" }}
                            className="submit-login bg-warning text-white mt-2"
                          >
                            SUBMIT
                          </Button>

                          <Button
                            variant="contained"
                            type="button"
                            style={{ width: "100%" }}
                            onClick={() => {
                              window.location.href =
                                "/dashboard/" + localStorage.getItem("uid");
                            }}
                            className="submit-login bg-white mt-2"
                          >
                            CANCEL
                          </Button>
                        </div>
                      </div>

                      {this.state.showLoader && (
                        <div className="loader-container">
                          <div className="loader">
                            <img
                              src={
                                "https://storage.googleapis.com/pettag/qr/assets/loader.gif"
                              }
                              alt="Loading..."
                              loading="lazy"
                              style={{ width: "100px", height: "100px" }}
                            />
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </>

          <div style={{ marginTop: "1rem" }} className="FooterWrapper">
            {" "}
            <Footer />{" "}
          </div>
        </div>

        <Snackbar
          open={this.state.snackbaropen}
          autoHideDuration={3000}
          onClose={this.snackbarClose}
          message={this.state.snackbarmsg}
          action={[
            <IconButton
              key="close"
              arial-label="Close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              X
            </IconButton>,
          ]}
        />
        <Dialog open={imagemodal} fullWidth="sm" maxWidth="sm">
          <DialogTitle>Image</DialogTitle>
          <DialogContent>
            {this.state?.imagecroploading ? (
              <p>Loading..</p>
            ) : (
              <>
                <form>
                  <div style={{ margin: "1rem" }}>
                    {!uploaderror ? (
                      <>
                        <ReactCrop
                          keepSelection
                          crop={crop}
                          aspect={1}
                          minWidth={100}
                          onChange={(percentCrop) => {
                            this.setState({ crop: percentCrop });
                          }}
                        >
                          <img
                            ref={this.imageref}
                            alt="crop"
                            width="100%"
                            height="100%"
                            src={imageUrl}
                            onLoad={this.cropsetup}
                          ></img>
                        </ReactCrop>
                      </>
                    ) : (
                      <>
                        {this.state.errorstate ? (
                          <>
                            <p style={{ color: "red", textAlign: "center" }}>
                              failed
                            </p>
                            <p style={{ color: "red", textAlign: "center" }}>
                              {this.state.errorstate}
                            </p>
                          </>
                        ) : (
                          <>
                            <p style={{ color: "red", textAlign: "center" }}>
                              {message}
                            </p>
                          </>
                        )}
                      </>
                    )}
                  </div>

                  <div className="crop-actionsSub">
                    <Button onClick={this.handelcancel}>Cancel</Button>
                    {!uploaderror ? (
                      <Button onClick={this.handelimagecrop}>Submit</Button>
                    ) : null}
                  </div>
                </form>
              </>
            )}
          </DialogContent>
          <canvas ref={this.canvasref} style={{ display: "none" }}></canvas>
        </Dialog>
      </>
    );
  }
}
