import React, { useEffect, useState } from "react";
// import Buy from "../../../assets/Post_11.png";
// import SignUp from "../../../assets/Post_22.png";
// import How from "../../../assets/howitw.jpg";
import "./howtogetstarted.component.css";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { switchUrls } from "../../../api/index.js";

const baseURL = switchUrls("gateway");

function Started() {
  const [keytag, setkeytag] = useState("");
  function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  let usertoken = readCookie("token");
  const token = usertoken;
  useEffect(() => {
    axios
      .get(
        `${baseURL}/api/getKeyDashboardDetails/` + localStorage.getItem("uid"),
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data[0].tag_number) {
          setkeytag(res.data[0].tag_number);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  });

  const getData = async () => {
    // const res = await axios.get("https://api.ipify.org/?format=json&flags=1");
    // console.log(res, "ipddddfd");
    // setIP(res.data.ip);
    // const ipifyResponse = await axios.get();

    const ipApiResponse = await fetch("https://ipapi.co/json/");
    const country = await ipApiResponse.json();
    const ipAddress = country.country;

    // const country = ipApiResponse;
    console.log(ipAddress, "counttryryr");
    const homeUrl =
      ipAddress === "IN" ? "https://www.tag8.in/" : "https://www.tag8.co/";
    document.getElementById("homeLink").href = homeUrl;
  };
  return (
    <div
      className="started mb-5"
      style={{ backgroundColor: "white !important" }}
    >
      {keytag ? (
        ""
      ) : (
        <div className="started">
          <h1 style={{ textAlign: "center" }}>How to get Started? </h1>
          <div className="started__tiles">
            <div
              className="started__tile"
              style={{ maxHeight: "400px", textAlign: "center" }}
            >
              <img
                src={"https://storage.googleapis.com/pettag/qr/Post_11.png"}
              ></img>
              <p>
                Order your <strong>Key Tag</strong>
              </p>
              <a
                className="text-white font-weight-bold"
                id="homeLink"
                href="https://www.tag8.in/"
              >
                BUY NOW
              </a>
            </div>
            <div
              className="started__tile"
              style={{ width: "400px", height: "400px", textAlign: "center" }}
            >
              <img
                src={"https://storage.googleapis.com/pettag/qr/Post_22.png"}
              ></img>
              <p>Sign-up for a tag8 account</p>
              <a className="text-white font-weight-bold" href="/signup">
                SIGN-UP
              </a>
            </div>
          </div>
        </div>
      )}
      <h1 style={{ padding: "50px", textAlign: "center" }}>How it works?</h1>
      <center>
        <img
          className="started__how"
          src={"https://storage.googleapis.com/pettag/qr/howitwbag.jpg"}
          style={{ textAlign: "center" }}
        ></img>
      </center>
    </div>
  );
}

export default Started;
