import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./vehicleactivation.component.css";
import "../vehicledetails/vehicledetails.component.css";
// import Backpack from "../../assets/Luggage.png";
import "font-awesome/css/font-awesome.min.css";
import $ from "jquery";
import Modal from "@material-ui/core/Modal";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import Navbar from "../header/header.component.jsx";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import setCanvasPreview from "../../setcanvasPreview.js";
import imageCompression from "browser-image-compression";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tooltip,
} from "@material-ui/core";
import { APP_ENV, switchUrls } from "../../api/index.js";

import heic2any from "heic2any";


import Footer from "../mainfooter/footerblack.jsx";
import Sidebarcontent from "../sidebar/Sidebar.jsx";
import MEDICAL_VALUABLE_IMAGE from "../../utils/Helpers.js";
import Inputfield from "../ui/Inputfield.jsx";
import Alert from '@mui/material/Alert';

const baseURL = switchUrls("vehicle");
const gateway_frontend = switchUrls("gateway_frontend");

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];

    while (c.charAt(0) === " ") c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
}

let usertoken = readCookie("token");



export default class BagActivation extends React.Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
    this.imageref = React.createRef();
    this.canvasref = React.createRef();
    this.state = {
      imageUrl: "",
      selectedImage: null,
      vehicle_imagelocation: null,
      bag_imagename: null,

      tag_number: this.props.match.params.tag_number,
      tag_status: "active",
      valuable_type: "Luggage",
      vectorurl: "",
      locationData: {
        latitude: null,
        longitude: null,
      },
      showLocationErrorModal: false,
      modalShown: false,
      retryLocation: false,
      valuable_id: 12,
      imageUrlvector:
        "https://storage.googleapis.com/pettag/qr-vehicle/vectors/car%20(1).png",
      valuableTypeToIdMap: {
        "Laptop Bag": 1,
        Briefcase: 2,
        "Camera Bag": 3,
        "Gym Bag": 4,
        "Trolley Bag": 5,
        Suitcase: 6,
        "Ladies Purse": 7,
        "Sports Kit Bag": 8,
        "Duffel Bag": 9,
        "Other Bags": 10,
        "School Bag": 11,
        Luggage: 12,
      },
      valuableImages: {
        1: "laptopbag.png",
        2: "briefcase.png",
        3: "camerabag.png",
        4: "GymBag.png",
        5: "trolley-bag.png",
        6: "suitcase.png",
        7: "ladiespurse.png",
        8: "sport-bag.png",
        9: "duffelbag.png",
        10: "other.png",
        11: "schoolbag.png",
        12: "luggage.png",
      },
      description: "",
      date_of_purchase: "",
      warranty_period: "",
      bag_color: "",
      bag_brand: "",
      snackbaropen: false,
      snackbarmsg: "",
      message: "",
      sidebar: true,
      subMenu: true,
      // isLoading: true,
      showLoader: true,
      imagemodal: false,
      crop: "",
      uploaderror: false,
      imagecroploading: false,
      errorstate: null,
      croperror: false,
      digitalqrdetails: {
        digital_product: false,
        no_of_days: null,
      },
      journey_startdate: "",
      journey_enddate: "",
      medical_tag: false,
      display_name: localStorage?.getItem("name"),
      current_lock: "",
      display_baglock: false,
      warranty_time: ["Days", "Months", "Years"],
      warranty_time_period: "",
      number_plate: "",
      model_name: "",
      model_color: "",
      Car_brand : ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.onImgChange = this.onImgChange.bind(this);
    this.showSidebar = this.showSidebar.bind(this);
    this.showSubMenu = this.showSubMenu.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.cropsetup = this.cropsetup.bind(this);
    this.handelimagecrop = this.handelimagecrop.bind(this);
    this.handelcancel = this.handelcancel.bind(this);
    this.uploadImg = this.uploadImg.bind(this);

    this.handleOk = this.handleOk.bind(this);
    this.handelwarranty = this.handelwarranty.bind(this);
    this.checkLocation = this.checkLocation.bind(this)
  }
  handelwarranty(e) {
    this.setState({
      [e?.target?.name]: e?.target?.value,
    });
  }
  handelcancel() {
    this.setState({
      uploaderror: false,
      message: "",
      imagemodal: false,
      crop: "",
      selectedImage: "",
      imageUrl: "",
    });
  }
  uploadImg() {
    this.setState({
      uploaderror: false,
      message: false,
      imagemodal: false,
      imagecroploading: false,
      croperror: false,
    });
  }

  componentDidMount() {
    // console.log(usertoken);
    $("#profile-image-uploadActi").on("click", function () {
      $("#choose-profile-imageActi").trigger("click");
    });

    $("#profile-image-uploadActi-1").on("click", function () {
      $("#choose-profile-imageActi").trigger("click");
    });

    window.addEventListener("load", this.handlePageLoad);
    setTimeout(this.handlePageLoad, 3000);

    setTimeout(() => {
      this.setState({ showLoader: false });
    }, 1000);

    this.getLocation();
    this.checkLocation()
  }

  componentWillUnmount() {
    window.removeEventListener("load", this.handlePageLoad);
  }

  handleOk = () => {
    this.setState({
      showLocationErrorModal: false,
      modalShown: true,
      retryLocation: false, // Reset retryLocation on OK click
    });
  };

  getLocation = (callback) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.setState(
            {
              locationData: {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              },
              showLocationErrorModal: false,
              modalShown: false,
              retryLocation: false,
            },
            callback // Execute callback after setting state
          );
        },
        (error) => {
          this.setState({
            showLocationErrorModal: true,
            modalShown: true,
          });
      

       
        }
      );
    } else {
      this.setState({
        showLocationErrorModal: true,
        modalShown: true,
      });
      console.error("Geolocation is not supported by this browser.");
    }
  };
  checkLocation = ()=>{
    navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
      permissionStatus.onchange = () => {
      
        if (permissionStatus.state === 'granted') {
          this.getLocation();
          // You can trigger a function here to re-fetch location data
        }
        else{
          this.setState({
            showLocationErrorModal: true,
          });
        }
      };
    });
  }

  submitHandler = (e) => {
    if (e) e.preventDefault();


      this.submitForm();
    
  };

  submitForm = () => {
    const {
      imageUrl,

      vehicle_imagelocation,
      imageUrlvector,
      Car_brand,
      display_name,
      number_plate,
      model_name,
      model_color,
    } = this.state;

    const { latitude, longitude } = this.state.locationData;

    const data = {
      tag_number: this.props.match.params.tag_number,

      imageUrl,
      Car_brand,
      latitude: latitude || null,
      longitude: longitude || null,
      vehicle_imagelocation: vehicle_imagelocation || null,
      imageUrlvector,
      display_name,
      number_plate,
      model_name,
      model_color,
    };

    let activationurl = `${baseURL}/api/vehicle/vehicleactivation`;

    axios
      .post(activationurl, data, {
        headers: { Authorization: usertoken },
      })
      .then((response) => {
        if (response.data?.status) {
          if (this.state?.selectedImage !== null) {
            const fd = new FormData();
            fd.append("image", this.state.selectedImage);
            fd.append("tag_number", this.props.match.params.tag_number);
            this.setState({ showLoader: true });
            axios
              .patch(`${baseURL}/api/vehicle/vehicleimageupload`, fd)
              .then((res) => {
                console.log(res);
                this.setState({
                  snackbaropen: true,
                  snackbarmsg: "Tag Activated Successfully",
                  showLoader: false,
                });
                setTimeout(() => {
                  if (APP_ENV === "local") {
                    window.location.href = `${gateway_frontend}/#/dashboard/`;
                  } else {
                    window.location.href = `/dashboard/${localStorage.getItem(
                      "uid"
                    )}`;
                  }
                }, 1000);
              })
              .catch((error) => {
                console.error(error.message);
                this.setState({
                  snackbaropen: true,
                  snackbarmsg:
                    error.response.data.error || "Image upload failed",
                });
                setTimeout(() => {
                  if (APP_ENV === "local") {
                    window.location.href = `${gateway_frontend}/#/dashboard/`;
                  } else {
                    window.location.href = `/dashboard/${localStorage.getItem(
                      "uid"
                    )}`;
                  }
                }, 1000);
              });
          } else {
            this.setState({
              snackbaropen: true,
              snackbarmsg:
                response?.data?.message || "Vehicle Activated Successfully",
              showLoader: false,
            });
            setTimeout(() => {
              if (APP_ENV === "local") {
                window.location.href = `${gateway_frontend}/#/dashboard/`;
              } else {
                window.location.href = `/dashboard/${localStorage.getItem(
                  "uid"
                )}`;
              }
            }, 2000);
          }
        } else {
          this.setState({
            snackbaropen: true,
            snackbarmsg: response?.data?.message || "Vehicle Activation Failed",
            showLoader: false,
          });
          setTimeout(() => {
            window.location.href = `/dashboard/${localStorage.getItem("uid")}`;
          }, 2000);
        }
      })
      .catch((error) => {
        console.log("Error:", error.response?.data?.message);
        this.setState({
          snackbaropen: true,
          snackbarmsg: error.response?.data?.message || "Vehicle Activation Failed",
          showLoader: false,
        });
      });
  };

  onImgChange(event) {
    this.setState({ imagesArray: "", file: [null] });
    this.fileObj = [];
    this.fileArray = [];
    this.setState({
      imagesArray: [...this.state.imagesArray, ...event.target.files],
    });
    this.fileObj.push(event.target.files);

    for (let i = 0; i < this.fileObj[0].length; i++) {
      this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]));
    }

    this.setState({ file: this.fileArray });
  }
  handleDropdownChange(event) {
    const selectedValuableType = event.target.value;
    const selectedId = this.state.valuableTypeToIdMap[selectedValuableType];
    if (!this.state?.medical_tag) {
      this.setState({
        valuable_type: selectedValuableType,
        valuable_id: selectedId,
        imageUrlvector: `https://storage.googleapis.com/pettag/qr/assets/${this.state.valuableImages[selectedId]}`,
        vehicle_imagelocation: null,
      });
    } else {
      this.setState({
        valuable_type: selectedValuableType,
        valuable_id: selectedId,
        vehicle_imagelocation: null,
        imageUrlvector: MEDICAL_VALUABLE_IMAGE?.get(selectedValuableType),
      });
    }
  }
  imageSize(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const promise = new Promise((resolve, reject) => {
      reader.onload = function (e) {
        const image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          const { height, width } = this;
          resolve({ height, width });
        };
        image.onerror = reject;
      };
    });

    return promise;
  }

  async handleChange(event) {
    this.setState({ imagecroploading: true });
    if (event.target.files[0]) {
      var sizeinmb = event.target.files[0].size / (1024 * 1024);

      this.setState({ showLoader: true });
      const imageDimension = await this.imageSize(event.target.files[0]);
      //console.log(imageDimension);
      const options = {
        maxSizeMB: 3,
        maxWidthOrHeight:
          imageDimension?.width > 1300 ? 1300 : imageDimension?.width,
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(
        event.target.files[0],
        options
      );
      //console.log(compressedFile);

      const selectedImage = compressedFile;
      //console.log(selectedImage);
      this.setState({ showLoader: false });

      if (selectedImage?.type) {
        if (
          selectedImage?.type === "image/jpeg" ||
          selectedImage?.type === "image/png" ||
          selectedImage?.type === "image/gif"
        ) {
          this.setState({
            imagemodal: true,
            selectedImage: compressedFile,
            imageUrl: URL.createObjectURL(compressedFile),
            isuploading: true,
            imagecroploading: false,
          });
        } else if (
          selectedImage?.type === "image/heic" ||
          selectedImage?.type === "image/heif"
        ) {
          const convertedImageBlob = await heic2any({
            blob: compressedFile,
            toType: "image/jpeg",
            quality: 0.1, // cuts the quality and size
          });
          //console.log(convertedImageBlob);
          let sizeinmb = convertedImageBlob?.size / (1024 * 1024);
          console.log(sizeinmb);
          if (sizeinmb > 5) {
          }
          this.setState({
            imagemodal: true,
            selectedImage: compressedFile,
            imageUrl: URL.createObjectURL(compressedFile),
            isuploading: true,
            imagecroploading: false,
          });
        } else {
          this.setState({
            message: "Only Png and Jpeg Images Are Acceptable",
            uploaderror: true,
            imagemodal: true,
            imagecroploading: false,
          });
        }
      } else {
        this.setState({
          message: "Only Png and Jpeg Images Are Acceptable",
          uploaderror: true,
          imagemodal: true,
          imagecroploading: false,
        });
      }

      if (this.fileInputRef.current) {
        this.fileInputRef.current.value = "";
      }
    }
  }

  showSidebar(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      sidebar: !currentState.sidebar,
    }));
  }

  showSubMenu(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      subMenu: !currentState.subMenu,
    }));
  }
  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };
  deleteimage = (e) => {
    if (this.state.imageUrl) {
      const formattedValuableType = this.state.valuable_type.replace(
        /([a-z])([A-Z])/g,
        "$1 $2"
      );
      const selectedId = this.state.valuableTypeToIdMap[formattedValuableType];
      const selectedImageFilename = this.state.valuableImages[selectedId];
      // const imageUrlvectors = `https://storage.googleapis.com/pettag/qr/assets/${selectedImageFilename}`;
      this.setState({
        imageUrl: null,
        imageUrlvectors: `https://storage.googleapis.com/pettag/qr/assets/${selectedImageFilename}`,
        selectedImage: null,
      });
    }
  };

  cropsetup(e) {
    const { width, height } = e.currentTarget;
    const cropminwidth = (150 / width) * 100;
    const cropAr = makeAspectCrop(
      {
        unit: "%",
        width: cropminwidth,
      },
      1,
      width,
      height
    );
    console.log(cropAr);
    const cropcenter = centerCrop(cropAr, width, height);

    this.setState({ crop: cropcenter });
  }
  async handelimagecrop() {
    try {
      this.setState({ imagecroploading: true });

      setCanvasPreview(
        this.imageref?.current,
        this.canvasref?.current,
        convertToPixelCrop(
          this.state.crop,
          this.imageref?.current?.width,
          this.imageref?.current?.height
        )
      );

      this.canvasref?.current.toBlob(
        async (blob) => {
          try {
            if (blob) {
              let fileType = blob?.type; // Get the MIME type of the Blob
              let file = new File([blob], this.state?.selectedImage?.name, {
                type: fileType || "image/jpeg",
              });

              if (file?.size) {
                let sizeinmb = file?.size / (1024 * 1024);
                if (sizeinmb > 5) {
                  this.setState({
                    message: "Upload A low Quality Image",
                    uploaderror: true,
                    imagemodal: true,
                    imagecroploading: false,
                  });
                } else {
                  this.setState({
                    selectedImage: file,
                    imageUrl: URL.createObjectURL(blob),
                    key_imagelocation: null,
                    isuploading: true,
                    imagemodal: false,
                    imagecroploading: false,
                  });
                }
              }
            } else {
              this.setState({
                uploaderror: true,
                imagemodal: true,
                imagecroploading: false,
                message:
                  "Please upload images with dimensions no larger than 3000px x 3000px for Performing Croping",
                croperror: true,
              });
            }
          } catch (er) {
            this.setState({
              errorstate: `croping error :${er}`,
              isuploading: true,
              imagecroploading: false,
              uploaderror: true,
            });
          }
        },
        "image/jpeg",
        0.1
      );
    } catch (er) {
      this.setState({
        errorstate: `croping error :${er}`,
        isuploading: true,
        imagecroploading: false,
        uploaderror: true,
      });
    }
  }

  render() {
    const {
      imageUrl,
      imageUrlvector,
      vehicle_imagelocation,
      tag_number,
      date_of_purchase,
      warranty_period,
      valuable_type,
      description,
      bag_color,
      bag_brand,
      sidebar,
      subMenu,
      valuableTypeToIdMap,
      valuableImages,
      valuable_id,
      locationData,
      showLocationErrorModal,
      retryLocation,
      locationBlocked,
      uploaderror,
      message,
      imagemodal,
      crop,
      digitalqrdetails,
      journey_startdate,
      journey_enddate,
      medical_tag,
      display_name,
      current_lock,
      display_baglock,
      warranty_value,
      warranty_time,
      warranty_time_period,
      number_plate,
      model_name,
      model_color,
      Car_brand
    } = this.state;

    const { latitude, longitude } = locationData;
    const selectedId = valuableTypeToIdMap[valuable_type];

    const selectedImageFilename = valuableImages[selectedId];
    let vectorurl = `https://storage.googleapis.com/pettag/qr/assets/${selectedImageFilename}`;

    const today = new Date().toISOString().split("T")[0];

    document.onreadystatechange = () => {
      this.setState({ showLoader: true });
      if (document.readyState === "complete") {
        this.setState({ showLoader: false });
      }
    };

    return (
      <>
        <Navbar />
        <div className="editPetDetails-main-wrapper">
          <div className="bagActivation-left-wrapper">
            <Sidebarcontent />
          </div>

          <>
            <div className="bag-container">
              <div className="background-image">
                <div className="bag-activation-container">
                  <p>
                    <strong>Vehicle Activation</strong>
                  </p>

                  <div className="bag-activation-form-container">
                    {/* <form
                        onSubmit={this.submitHandler}
                        > */}
                    <form
                      className="bag-activation-form"
                      onSubmit={(e) => {
                        e.preventDefault();
                        this.submitHandler(e);
                      }}
                    >
                      <div className="editPetDetails-user-img">
                        {imageUrl?.length ? (
                          <>
                            <img
                              alt="imageurl"
                              src={imageUrl}
                              className="editPetDetails-image"
                            />
                          </>
                        ) : vehicle_imagelocation ? (
                          <>
                            <img
                              alt="imagelocation"
                              src={vehicle_imagelocation}
                              className="editPetDetails-image"
                            />
                          </>
                        ) : (
                          <>
                            <img
                              alt="imageyrlvector"
                              src={imageUrlvector}
                              className="editPetDetails-image"
                            />
                          </>
                        )}
                        <div className="editPetDetails-edit-btn-wrap">
                          <label
                            htmlFor="upload"
                            className="editPetDetails-edit-button-image"
                            style={{ cursor: "pointer" }}
                          >
                            <MdIcons.MdEdit /> Change
                          </label>

                          <input
                            id="upload"
                            className="editPetDetails-input-user-img"
                            type="file"
                            accept="image/*"
                            ref={this.fileInputRef}
                            onChange={this.handleChange}
                          />
                          {this.state.imageUrl ? (
                            <label
                              className="editPetDetails-delete-button-image "
                              onClick={this.deleteimage}
                            >
                              <AiIcons.AiFillDelete /> Remove
                            </label>
                          ) : null}
                        </div>
                        <div
                          className=" editPetDetails-user-qr mt-3 d-flex align-items-center justify-content-center"
                          style={{ gap: "6px" }}
                        >
                          <img
                            alt="qr"
                            src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                            style={{ width: "30px", height: "30px" }}
                          />
                          <p style={{ marginTop: "15px" }}>
                            <b>
                              {tag_number && tag_number !== "-"
                                ? tag_number
                                : "N/A"}
                            </b>
                          </p>
                        </div>
                      </div>

                      <div>
                      <div>
                          <Inputfield
                            label={"Display name"}
                            fieldname={"display_name"}
                            value={display_name}
                            onchange={this.changeHandler}
                            required={true}
                            labelprops={{ shrink: true }}
                          />
                        </div>
                        <Inputfield
                          label={"Number Plate"}
                          fieldname="number_plate"
                          type="text"
                          value={number_plate}
                          onchange={this.changeHandler}
                        />
                

                        <Inputfield
                          label={"Model Name"}
                          type="text"
                          fieldname={"model_name"}
                          value={model_name}
                          onchange={this.changeHandler}
                        />
                          <Inputfield
                          label={"Car Brand"}
                          type="text"
                          fieldname={"Car_brand"}
                          value={Car_brand}
                          onchange={this.changeHandler}
                        />
                        <Inputfield
                          label={"Color"}
                          type="text"
                          fieldname={"model_color"}
                          value={model_color}
                          onchange={this.changeHandler}
                        />

                   
                        <div className="bagactivation-btn-grp">
                          <Button
                            variant="contained"
                            type="submit"
                            style={{ width: "100%" }}
                            className="submit-login bg-warning text-white mt-2"
                          >
                            SUBMIT
                          </Button>

                          <Button
                            variant="contained"
                            type="button"
                            style={{ width: "100%" }}
                            onClick={() => {
                              window.location.href =
                                "/dashboard/" + localStorage.getItem("uid");
                            }}
                            className="submit-login bg-white mt-2"
                          >
                            CANCEL
                          </Button>
                        </div>
                      </div>
                      {this.state.showLoader && (
                        <div className="loader-container">
                          <div className="loader">
                            <img
                              src={
                                "https://storage.googleapis.com/pettag/qr/assets/loader.gif"
                              }
                              alt="Loading..."
                              loading="lazy"
                              style={{ width: "100px", height: "100px" }}
                            />
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </>

          <div style={{ marginTop: "1rem" }} className="FooterWrapper">
            {" "}
            <Footer />{" "}
          </div>
        </div>

        <Snackbar
          open={this.state.snackbaropen}
          autoHideDuration={3000}
          onClose={this.snackbarClose}
          message={this.state.snackbarmsg}
          action={[
            <IconButton
              key="close"
              arial-label="Close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              X
            </IconButton>,
          ]}
        />
        <Dialog open={imagemodal} fullWidth="sm" maxWidth="sm">
          <DialogTitle>Image</DialogTitle>
          <DialogContent>
            <form>
              <div style={{ margin: "1rem" }}>
                {!uploaderror ? (
                  <>
                    <ReactCrop
                      keepSelection
                      crop={crop}
                      aspect={1}
                      minWidth={100}
                      onChange={(percentCrop) => {
                        this.setState({ crop: percentCrop });
                      }}
                    >
                      <img
                        ref={this.imageref}
                        alt="crop"
                        width="100%"
                        height="100%"
                        src={imageUrl}
                        onLoad={this.cropsetup}
                      ></img>
                    </ReactCrop>
                  </>
                ) : (
                  <>
                    <p style={{ color: "red", textAlign: "center" }}>
                      {message}
                    </p>
                  </>
                )}
              </div>

              <div className="crop-actionsSub">
                <Button onClick={this.handelcancel}>Cancel</Button>
                {!uploaderror ? (
                  <Button onClick={this.handelimagecrop}>Submit</Button>
                ) : null}
              </div>
            </form>
          </DialogContent>
          <canvas ref={this.canvasref} style={{ display: "none" }}></canvas>
        </Dialog>

        <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={showLocationErrorModal}
        autoHideDuration={6000}
        onClose={() => this.setState({ showLocationErrorModal: false })} // Use Snackbar's onClose prop
       
      >
        <Alert
          style={{ backgroundColor: "#90EE90" }}
          onClose={() => {this.setState({showLocationErrorModal:false})}}
          severity="success"
          sx={{ width: "100%" }}
        >
        To enhance your experience, please enable GPS. This step is optional, and you can still proceed without enabling it.
        </Alert>
      </Snackbar>
      </>
    );
  }
}
